<template>
  <div class="animated fadeIn">
    <b-card header="Grandes Numeros">
      <b-row>
        <b-col md="3">
          <b-card header="Terreno (he)">
            <terreno :height="300" class="chart-wrapper"></terreno>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Posiciones Comerciales (N°)">
            <numero-posiciones-comerciales :height="300" class="chart-wrapper"></numero-posiciones-comerciales>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Espacio Locales Comerciales (m²)">
            <m2-espacio-locales-comerciales :height="300" class="chart-wrapper"></m2-espacio-locales-comerciales>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Posiciones Comerciales (m²)">
            <m2-posiciones-comerciales :height="300" class="chart-wrapper"></m2-posiciones-comerciales>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Productos Comercializados (ton)">
            <productos-comercializados :height="300" class="chart-wrapper"></productos-comercializados>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Empresas Participantes">
            <empresas-participantes :height="300" class="chart-wrapper"></empresas-participantes>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Trabajadores">
            <trabajadores :height="300" class="chart-wrapper"></trabajadores>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Residuos Disposición Final (ton)">
            <residuos-disposicion-final :height="300" class="chart-wrapper"></residuos-disposicion-final>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Vehiculos que Acceden">
            <vehiculos :height="300" class="chart-wrapper"></vehiculos>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Recuperación Alimentaria (ton)">
            <recuperacion-alimentaria :height="300" class="chart-wrapper"></recuperacion-alimentaria>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Consumo Energia (Kw)">
            <kw-consumo-energia :height="300" class="chart-wrapper"></kw-consumo-energia>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Consumo Agua (m³)">
            <m3-consumo-agua :height="300" class="chart-wrapper"></m3-consumo-agua>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Visitas Diarias">
            <visitas :height="300" class="chart-wrapper"></visitas>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card header="Reciclaje (%)">
            <reciclaje :height="300" class="chart-wrapper"></reciclaje>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Top">
      <b-row>
        <b-col md="6">
          <b-card header="Frutas (ton)">
            <frutas :height="150" class="chart-wrapper"></frutas>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Hortalizas (ton)">
            <hortalizas :height="150" class="chart-wrapper"></hortalizas>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Seguridad">
      <b-row>
        <b-col md="6">
          <b-card header="Incidentes">
            <incidentes :height="150" class="chart-wrapper"></incidentes>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Montos en Robo">
            <robos :height="150" class="chart-wrapper"></robos>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Apoyo al pequeño agricultor">
      <b-row>
        <b-col md="6">
          <b-card header="Agricultores participantes en el Convenio INDAP - MMLV">
            <convenio :height="150" class="chart-wrapper"></convenio>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Mercadería Transadas (ton)">
            <transados :height="150" class="chart-wrapper"></transados>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Inocuidad Alimentaria">
      <b-row>
        <b-col md="12">
          <b-card header="Programa de Buenas Prácticas de Manipulación BPM Más Limpio Más Sano">
            <programa :height="75" class="chart-wrapper"></programa>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Nuestra Gente">
      <b-row>
        <b-col md="6">
          <b-card header="Colaboradores según tipo de contrato (2018)">
            <contrato :height="150" class="chart-wrapper"></contrato>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Número de colaboradores según nivel organizacional y género (2018)">
            <organizacional-genero :height="150" class="chart-wrapper"></organizacional-genero>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Número de colaboradores según rangos de edad (2018)">
            <edad :height="150" class="chart-wrapper"></edad>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Colaboradores según grupos de beneficios (2018)">
            <beneficios :height="150" class="chart-wrapper"></beneficios>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Colaboradores Satisfechos">
            <clima :height="150" class="chart-wrapper"></clima>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Evaluación de Desempeño">
            <desempeno :height="150" class="chart-wrapper"></desempeno>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Salud y Seguridad Ocupacional">
            <salud-seguridad :height="150" class="chart-wrapper"></salud-seguridad>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Higiene y Seguridad">
      <b-row>
        <b-col md="12">
          <b-card header="Programa de Control de Plagas y Promoción de higiene y Seguridad">
            <programa-control :height="75" class="chart-wrapper"></programa-control>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Gestión de residuos">
      <b-row>
        <b-col md="6">
          <b-card header="Porcentajes de Reciclaje">
            <porcentajes :height="150" class="chart-wrapper"></porcentajes>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Reciclaje (kg)">
            <kg-reciclados :height="150" class="chart-wrapper"></kg-reciclados>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Recicladores y Estaciones">
            <recicladores-estaciones :height="150" class="chart-wrapper"></recicladores-estaciones>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Materiales">
            <materiales :height="150" class="chart-wrapper"></materiales>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="text-white bg-info" header="Ahorro de Agua">
            <div class="h1 text-muted text-right mb-4">
              <i class="icon-drop"></i>
            </div>
            <div class="h4 mb-0">4.755.752</div>
            <small class="text-muted text-uppercase font-weight-bold">Litros de Agua Ahorrados</small>
            <b-progress :value="75" class="progress-white progress-xs mt-3" height={}></b-progress>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card class="text-white bg-success" header="Arboles Salvados">
            <div class="h1 text-muted text-right mb-4">
              <i class="icon-globe"></i>
            </div>
            <div class="h4 mb-0">4.448</div>
            <small class="text-muted text-uppercase font-weight-bold">Arboles NO talados</small>
            <b-progress :value="60" class="progress-white progress-xs mt-3" height={}></b-progress>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card header="Otros">
      <b-row>
        <b-col md="6">
          <b-card header="Material Organica Valorizada">
            <material-organico-valorizado :height="150" class="chart-wrapper"></material-organico-valorizado>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Programa de Material Orgánico para Alimento Animal">
            <div class="brand-card">
              <div class="brand-card-header bg-green" style="height: 142px">
                <div class="chart-wrapper">
                  <material-alimento-animal/>
                </div>
              </div>
              <div class="brand-card-body">
                <div>
                  <div class="text-value">$ 30.444.654</div>
                  <div class="text-uppercase text-muted small">Ahorros de disposición de Relleno</div>
                </div>
                <div>
                  <div class="text-value">6.357 m³</div>
                  <div class="text-uppercase text-muted small">No se ocuparon con Basura</div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Consumo de Agua">
            <div class="brand-card">
              <div class="brand-card-header bg-light-blue" style="height: 142px">
                <div class="chart-wrapper">
                  <consumo-agua/>
                </div>
              </div>
              <div class="brand-card-body">
                <div>
                  <div class="text-value">30 %</div>
                  <div class="text-uppercase text-muted small">Porcentaje de disminución</div>
                </div>
                <div>
                  <div class="text-value">$ 105.922.227</div>
                  <div class="text-uppercase text-muted small">Ahorro</div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="Programa de control y técnicas responsables de mascotas">
            <comunidad :height="150" class="chart-wrapper"></comunidad>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
    import CardLine1ChartExample from './dashboard/CardLine1ChartExample'
    import CardLine2ChartExample from './dashboard/CardLine2ChartExample'
    import CardLine3ChartExample from './dashboard/CardLine3ChartExample'
    import CardBarChartExample from './dashboard/CardBarChartExample'
    import MainChartExample from './dashboard/MainChartExample'
    import SocialBoxChartExample from './dashboard/SocialBoxChartExample'
    import CalloutChartExample from './dashboard/CalloutChartExample'
    import {Callout} from '@coreui/vue'
    import Siembra from "./indicadores/produccion/Siembra";
    import Cosecha from "./indicadores/produccion/Cosecha";
    import Procesamiento from "./indicadores/produccion/Procesamiento";
    import Mortalidad from "./indicadores/produccion/Mortalidad";
    import Antibioticos from "./indicadores/produccion/Antibioticos";
    import RelacionAntibioticos from "./indicadores/produccion/RelacionAntibioticos";
    import Alimentos from "./indicadores/produccion/Alimentos";
    import AlimentosConsumidos from "./indicadores/produccion/AlimentosConsumidos";
    import Sanciones from "./indicadores/legal/Sanciones";
    import Ambiental from "./indicadores/legal/Ambiental";
    import Laboral from "./indicadores/legal/Laboral";
    import Municipal from "./indicadores/legal/Municipal";
    import Deposiciones from "./indicadores/biodiversidad/Deposiciones";
    import Psicicultura from "./indicadores/agua/Psicicultura";
    import Chaparro from "./indicadores/agua/Chaparro";
    import Molco from "./indicadores/agua/Molco";
    import Fonck from "./indicadores/agua/Fonck";
    import UsoPlantas from "./indicadores/agua/UsoPlantas";
    import Generadores from "./indicadores/residuos/Generadores";
    import Relleno from "./indicadores/residuos/Relleno";
    import GestionReciclaje from "./indicadores/residuos/GestionReciclaje";
    import TotalEmisiones from "./indicadores/emisiones/TotalEmisiones";
    import Alcances from "./indicadores/emisiones/Alcances";
    import Huella from "./indicadores/emisiones/Huella";
    import Sexo from "./indicadores/empleo/Sexo";
    import Sindicato from "./indicadores/empleo/Sindicato";
    import Accidentes from "./indicadores/empleo/Accidentes";
    import Fatalidad from "./indicadores/empleo/Fatalidad";
    import TotalCompras from "./indicadores/proveedores/TotalCompras";
    import PorcentajeCompras from "./indicadores/proveedores/PorcentajeCompras";
    import TotalInversion from "./indicadores/comunidad/TotalInversion";
    import Inversiones from "./indicadores/comunidad/Inversiones";
    import TotalResiduos from "./indicadores/residuos/TotalResiduos";
    import Terreno from "./indicadores/bignumbers/Terreno";
    import NumeroPosicionesComerciales from "./indicadores/bignumbers/NumeroPosicionesComerciales";
    import M2EspacioLocalesComerciales from "./indicadores/bignumbers/M2EspacioLocalesComerciales";
    import M2PosicionesComerciales from "./indicadores/bignumbers/M2PosicionesComerciales";
    import ProductosComercializados from "./indicadores/bignumbers/ProductosComercializados";
    import EmpresasParticipantes from "./indicadores/bignumbers/EmpresasParticipantes";
    import Trabajadores from "./indicadores/bignumbers/Trabajadores";
    import ResiduosDisposicionFinal from "./indicadores/bignumbers/ResiduosDisposicionFinal";
    import Vehiculos from "./indicadores/bignumbers/Vehiculos";
    import RecuperacionAlimentaria from "./indicadores/bignumbers/RecuperacionAlimentaria";
    import KwConsumoEnergia from "./indicadores/bignumbers/KwConsumoEnergia";
    import M3ConsumoAgua from "./indicadores/bignumbers/m3ConsumoAgua";
    import Visitas from "./indicadores/bignumbers/Visitas";
    import Reciclaje from "./indicadores/bignumbers/Reciclaje";
    import Frutas from "./indicadores/top/Frutas";
    import Hortalizas from "./indicadores/top/Hortalizas";
    import Incidentes from "./indicadores/seguridad/Incidentes";
    import Robos from "./indicadores/seguridad/Robos";
    import Convenio from "./indicadores/apoyo/Convenio";
    import Transados from "./indicadores/apoyo/Transados";
    import Programa from "./indicadores/inocuidad/Programa";
    import Contrato from "./indicadores/gente/Contrato";
    import OrganizacionalGenero from "./indicadores/gente/OrganizacionalGenero";
    import Edad from "./indicadores/gente/Edad";
    import Beneficios from "./indicadores/gente/Beneficios";
    import Clima from "./indicadores/gente/Clima";
    import Desempeno from "./indicadores/gente/Desempeno";
    import SaludSeguridad from "./indicadores/gente/SaludSeguridad";
    import ProgramaControl from "./indicadores/higiene/ProgramaControl";
    import Porcentajes from "./indicadores/residuos/Porcentajes";
    import KgReciclados from "./indicadores/residuos/KgReciclados";
    import RecicladoresEstaciones from "./indicadores/residuos/RecicladoresEstaciones";
    import Materiales from "./indicadores/residuos/Materiales";
    import MaterialOrganicoValorizado from "./indicadores/otros/MaterialOrganicoValorizado";
    import MaterialAlimentoAnimal from "./indicadores/otros/MaterialAlimentoAnimal";
    import ConsumoAgua from "./indicadores/otros/ConsumoAgua";
    import Comunidad from "./indicadores/otros/Comunidad";


    export default {
  name: 'dashboard',
  components: {
      Comunidad,
      ConsumoAgua,
      MaterialAlimentoAnimal,
      MaterialOrganicoValorizado,
      Materiales,
      RecicladoresEstaciones,
      KgReciclados,
      Porcentajes,
      ProgramaControl,
      SaludSeguridad,
      Desempeno,
      Clima,
      Beneficios,
      Edad,
      OrganizacionalGenero,
      Contrato,
      Programa,
      Transados,
      Convenio,
      Robos,
      Incidentes,
      Hortalizas,
      Frutas,
      Visitas,
      M3ConsumoAgua,
      KwConsumoEnergia,
      RecuperacionAlimentaria,
      Vehiculos,
      ResiduosDisposicionFinal,
      Trabajadores,
      EmpresasParticipantes,
      ProductosComercializados,
      M2PosicionesComerciales,
      M2EspacioLocalesComerciales,
      NumeroPosicionesComerciales,
      Terreno,
      TotalResiduos,
      Inversiones,
      TotalInversion,
      PorcentajeCompras,
      TotalCompras,
      Fatalidad,
      Accidentes,
      Sindicato,
      Sexo,
      Huella,
      Alcances,
      TotalEmisiones,
      GestionReciclaje,
      Relleno,
      Generadores,
      UsoPlantas,
      Fonck,
      Molco,
      Chaparro,
      Psicicultura,
      Reciclaje,
      Deposiciones,
      Municipal,
      Laboral,
      Ambiental,
      Sanciones,
      AlimentosConsumidos,
      Alimentos,
      RelacionAntibioticos,
      Antibioticos,
      Mortalidad,
      Procesamiento,
      Cosecha,
      Siembra,
    Callout,
    CardLine1ChartExample,
    CardLine2ChartExample,
    CardLine3ChartExample,
    CardBarChartExample,
    MainChartExample,
    SocialBoxChartExample,
    CalloutChartExample
  },
  data: function () {
    return {
      selected: 'Month',
      tableItems: [
        {
          avatar: { url: 'img/avatars/1.jpg', status: 'success' },
          user: { name: 'Yiorgos Avraamu', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'USA', flag: 'us' },
          usage: { value: 50, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Mastercard', icon: 'fa fa-cc-mastercard' },
          activity: '10 sec ago'
        },
        {
          avatar: { url: 'img/avatars/2.jpg', status: 'danger' },
          user: { name: 'Avram Tarasios', new: false, registered: 'Jan 1, 2015' },
          country: { name: 'Brazil', flag: 'br' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Visa', icon: 'fa fa-cc-visa' },
          activity: '5 minutes ago'
        },
        {
          avatar: { url: 'img/avatars/3.jpg', status: 'warning' },
          user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'India', flag: 'in' },
          usage: { value: 74, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Stripe', icon: 'fa fa-cc-stripe' },
          activity: '1 hour ago'
        },
        {
          avatar: { url: 'img/avatars/4.jpg', status: '' },
          user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'France', flag: 'fr' },
          usage: { value: 98, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'PayPal', icon: 'fa fa-paypal' },
          activity: 'Last month'
        },
        {
          avatar: { url: 'img/avatars/5.jpg', status: 'success' },
          user: { name: 'Agapetus Tadeáš', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Spain', flag: 'es' },
          usage: { value: 22, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Google Wallet', icon: 'fa fa-google-wallet' },
          activity: 'Last week'
        },
        {
          avatar: { url: 'img/avatars/6.jpg', status: 'danger' },
          user: { name: 'Friderik Dávid', new: true, registered: 'Jan 1, 2015' },
          country: { name: 'Poland', flag: 'pl' },
          usage: { value: 43, period: 'Jun 11, 2015 - Jul 10, 2015' },
          payment: { name: 'Amex', icon: 'fa fa-cc-amex' },
          activity: 'Last week'
        }
      ],
      tableFields: {
        avatar: {
          label: '<i class="icon-people"></i>',
          class: 'text-center'
        },
        user: {
          label: 'User'
        },
        country: {
          label: 'Country',
          class: 'text-center'
        },
        usage: {
          label: 'Usage'
        },
        payment: {
          label: 'Payment method',
          class: 'text-center'
        },
        activity: {
          label: 'Activity'
        }
      }
    }
  },
  methods: {
    variant (value) {
      let $variant
      if (value <= 25) {
        $variant = 'info'
      } else if (value > 25 && value <= 50) {
        $variant = 'success'
      } else if (value > 50 && value <= 75) {
        $variant = 'warning'
      } else if (value > 75 && value <= 100) {
        $variant = 'danger'
      }
      return $variant
    },
    flag (value) {
      return 'flag-icon flag-icon-' + value
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
